import React from 'react';
import "../styles/components/testimonial.scss";

export function Testimonial({children, source}) {
  return (
    <section className="testimonial">
      <div className="container">
        <h2>Testimonial<span>.</span></h2>
        {children}
      </div>
    </section>
  );
}