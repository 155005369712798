import React from "react";
import {PageWrapperDark} from "../components/page-wrapper";
import { Testimonial } from "../components/testimonial";
import "../styles/pages/about.scss";

function About() {
  return (
    <PageWrapperDark>
      <main>
        <div className="about">
          <AboutBanner />
          <section>
            <div className="container">
              <div className="img-placeholder" />
              <h2>
                Quality Roofing Since <span>19XX</span>
              </h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Orci
                ac auctor augue mauris augue neque. Ultrices dui sapien eget mi
                proin sed libero enim sed. Orci ac auctor augue mauris augue
                neque. Ultrices dui sapien eget mi proin sed libero enim sed.
              </p>
            </div>
          </section>
          <Testimonial>
            <p>
              “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Orci
              ac auctor augue mauris augue neque.“
            </p>
            <h3 className="source">
              First Name<span>,</span> L
            </h3>
          </Testimonial>
        </div>
      </main>
    </PageWrapperDark>
  );
}

function AboutBanner() {
  return (
    <section className="about-banner">
      <div className="container">
        <h1>
          About us<span>.</span>
        </h1>
        <h2>Some kind of subheader</h2>
      </div>
    </section>
  );
}

export default About;
